import { useEffect, useState } from "react"
import Login from "../components/Login"
import { AddListingForm } from "../components/AddListingForm";
import axios from "axios";
import PropertyList from "../components/PropertyList";
import { message } from "antd";
import { EditListingModal } from "../components/EditFormModal";

export const AdminPage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [listings, setListings] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(true);

    useEffect(() => {
        if (shouldUpdate) {
            axios.get('https://nodejs-production-8b99.up.railway.app/admin/listings').then((res) => {
                setListings(res.data);
            }
            )
            setShouldUpdate(false);
            setIsEditVisible(false);
        }
    }
        , [shouldUpdate])



    const deleteListing = (id: string) => {
        console.log("The id is ", id)
        axios.delete(`https://nodejs-production-8b99.up.railway.app/listings/${id}`).then((res) => {
            // setListings(listings.filter((listing: any) => listing._id !== id))
            setShouldUpdate(true);
        }).then(() => {
            message.success('Úspech!');
        }).catch(() => {
            message.error('Nastala chyba');
        }
        )
    }

    const [listingToEdit, setListingToEdit] = useState({} as any);

    const editListing = (id: string) => {
        console.log("The id is ", id);
        console.log("The  ID ISSS ", id);
        setListingToEdit(listings.find((listing: any) => listing._id === id));
        setIsEditVisible(true);
    }


    const [isEditVisible, setIsEditVisible] = useState(false);

    const handleClose = () => {
        setIsEditVisible(false);
    }


    return (
        <div>
            <div style={{ padding: "0 15rem" }}>
                {isLoggedIn ? <div>
                     {!isEditVisible && <AddListingForm />}
                    <PropertyList properties={listings} deleteListing={deleteListing} editListing={editListing} />
                    <EditListingModal visible={isEditVisible} onClose={handleClose} listingData={listingToEdit} setShouldUpdate={setShouldUpdate}/>
                </div> : <Login setIsLoggedIn={setIsLoggedIn}  />}
            </div>
        </div>
    )
}

    export const getPropertyType = (propertyType: string) => {
        switch (propertyType) {
            case "family_house":
                return "Rodinný dom";
            case "cottage":
                return "Chata";
            case "country_house":
                return "Vidiecky dom";
            case "farm_estate":
                return "Farmárska usadlosť";
            case "mobile_house":
                return "Mobilný dom";
            case "houseboat":
                return "Hausbót";
            case "log_house":
                return "Zrub";
            case "garden_house":
                return "Záhradný domček";
            case "building":
                return "Budova";
            case "villa":
                return "Vila";
            default:
                return "Nehnuteľnosť";
        }
    }

    export const translateAttributes = (attribute: string) => {
        switch (attribute) {
            case "rooms":
                return "Izby";
            case "bathrooms":
                return "Kúpeľne";
            case "floorArea":
                return "Úžitková plocha";
            case "wholeArea":
                return "Celková plocha";
            case "listedDate":
                return "Dátum uvedenia na trh";
            case "price":
                return "Cena";
            case "propertyType":
                return "Typ nehnuteľnosti";
            default:
                return attribute;
        }
    }

import "./styles.css";
import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { Carousel, Col, Image, List, Modal, Row, Typography } from "antd";
import { Link, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getPropertyType, translateAttributes } from "./helpers";

import { ContactForm } from "../components/ContactForm";
import { Helmet } from 'react-helmet';
import axios from "axios";
import { returnPreviewUrl } from "../helpers";

const { Title } = Typography;

// const items: DescriptionsProps['items'] = [
//     { key: 1, label: 'Status', children: 'aktívne 🟢' },
//     { key: 2, label: 'Vlastníctvo', children: 'osobné 👤' },
//     { key: 3, label: 'Stav', children: 'čiastočne prerobený 🔨' },
//     { key: 4, label: 'Úžitková plocha', children: '65 m2 📐' },
//     { key: 5, label: 'Plocha pozemku', children: '468 m2 🌄' },
//     { key: 6, label: 'Počet podlaží', children: '2 🏢' },
//     { key: 7, label: 'Počet izieb', children: '2 🚪' },
//     { key: 8, label: 'Balkón', children: 'nie ❌' },
//     { key: 9, label: 'Počet kúpeľní', children: '1 🛁' },
//     { key: 10, label: 'El. napätie', children: '230/400V 🔌' },
//     { key: 11, label: 'Voda', children: 'studňa 🚰' },
//     { key: 12, label: 'Kanalizácia', children: 'žumpa 🚽' },
//     { key: 13, label: 'Zateplený objekt', children: 'nie ❌' },
//     { key: 14, label: 'Terasa', children: 'nie ❌' },
//     { key: 15, label: 'Vykurovanie', children: 'vlastné 🔥' },
// ];
// const contentStyle: React.CSSProperties = {
//     height: '100%',
//     color: '#fff',
//     lineHeight: '160px',
//     textAlign: 'center',
//     background: '#364d79',
// };




export const ListingPage: React.FC = () => {


    const id = useParams<{ '*': string }>()['*'];

    const [listing, setListing] = useState<any>(undefined);
    const [shouldUpdate, setShouldUpdate] = useState(true);

    useEffect(() => {
        if (shouldUpdate) {
            axios.get('https://nodejs-production-8b99.up.railway.app/listings/' + id).then((res) => {
                setListing(res.data);
                console.log("the res data is", res.data);
            }
            )
            setShouldUpdate(false);
        }
    }
        , [shouldUpdate, id])

    // console.log("the city is", city);
    let [searchParams] = useSearchParams();
    // const category1 = searchParams.getAll();
    const category = searchParams.get('category');


    const city = searchParams.get('city');
    console.log("category ", category);
    console.log('city ', city);

    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '100%',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreview = (imageUrl: string) => {
        setPreviewImage(imageUrl);
        setPreviewVisible(true);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
        setPreviewImage("");
    };

    console.log('okk ', Object.keys(listing ?? {}))
    console.log("the listing is ", listing)

    //     <Form.Item
    //     label="Typ nehnuteľnosti"
    //     name="propertyType"
    //     required
    //     rules={[{ required: true, message: 'Prosím, vyberte typ nehnuteľnosti!' }]}
    // >
    //     <Select>
    //         <Option value="family_house">Rodinný dom</Option>
    //         <Option value="cottage">Chata</Option>
    //         <Option value="country_house">Vidiecky dom</Option>
    //         <Option value="farm_estate">Farmárska usadlosť</Option>
    //         <Option value="mobile_house">Mobilný dom</Option>
    //         <Option value="houseboat">Hausbót</Option>
    //         <Option value="log_house">Zrub</Option>
    //         <Option value="garden_house">Záhradný domček</Option>
    //         <Option value="building">Budova</Option>
    //         <Option value="villa">Vila</Option>
    //     </Select>
    // </Form.Item>


    return <React.Fragment>
        <Helmet>
            <title>{listing?.title}</title>
            <meta property="og:title" content={listing?.title} />
            <meta property="og:description" content={listing?.description} />
            <meta property="og:image" content={listing?.photos[0]} />
            <meta property="og:type" content="website" />
            <meta property="og:URL" content={"https://realityaandt.com/properties/" + id} />
        </Helmet>
        <Row style={{ padding: "1rem" }}>
            <Col xs={24} >
                <Link to="/#list" className="backButton">
                    <ArrowLeftOutlined /> Späť na všetky inzeráty
                </Link>
            </Col>
            <Col xs={24} md={0} style={{ margin: 0, padding: 0 }}>  <Title level={2} style={{ margin: 0, padding: 0 }}>{listing?.title}</Title></Col>

            <Col xs={24} md={0}>
                <div style={{
                    width: "100%"
                }}>


                    <Carousel autoplay arrows autoplaySpeed={1000 * 1.5} nextArrow={<LeftOutlined color="red" />}
                        dots
                    >
                        {
                            listing?.photos.map((image: any) => {
                                return <h3 style={contentStyle}>
                                    <Image
                                        src={listing.hasPreviews ? returnPreviewUrl(image) : image}
                                        style={{
                                            height: '100%',
                                            borderRadius: "10px"
                                        }}
                                    />
                                </h3>
                            })
                        }
                    </Carousel>
                    <Row gutter={[1, 1]}>
                        {
                            // @ts-ignore
                            listing?.photos?.map((photo, index) => (
                                <Col key={index} span={6}>
                                    <img
                                        src={listing.hasPreviews ? returnPreviewUrl(photo) : photo}
                                        alt={`Property ${index}`}
                                        style={{ width: '100%', cursor: 'pointer', borderRadius: "5px" }}
                                        onClick={() => handlePreview(photo)}
                                    />
                                </Col>
                            ))}
                    </Row>
                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="Preview" style={{ width: '100%', borderRadius: "3rem" }} src={previewImage} />
                    </Modal>

                </div>
            </Col>

            <Col xs={24} md={12}>
                <Col xs={0} md={24}>  <Title level={2}>{listing?.title}</Title></Col>

                <Row gutter={[32, 32]} style={{ padding: '1.5rem' }}>
                    {/* <Title level={2}>Popis nehnuteľnosti</Title> */}


                    <List
                        // bordereds
                        dataSource={Object.keys(listing ?? {})}
                        style={{ width: "100%" }}
                        renderItem={(item) => {
                            if (["price", "rooms", "bathrooms", "floorArea", "wholeArea"].includes(item))
                                return (
                                    <List.Item>
                                        {/* <Typography.Text mark></Typography.Text> */}
                                        <Row style={{ width: "100%" }}>
                                            <Col span={12}>
                                                <span style={{ color: "silver", fontSize: item === "price" ? 32 : 16 }}>{translateAttributes(item)}</span>
                                            </Col>
                                            <Col span={12}>
                                                {<span style={{
                                                    color: item === "price" ? "gold" : "black", fontSize: item === "price" ? 32 : 16,
                                                    fontWeight: item === "price" ? "bold" : "inherit"
                                                }}>{listing[item]}</span>}
                                                <span style={{ color: item === "price" ? "gold" : "black", fontSize: item === "price" ? 32 : 16, fontWeight: item === "price" ? "bold" : "inherit" }}>{
                                                    item === "price" ? "€" : item === "floorArea" || item === "wholeArea" ? " m²" : ""
                                                }</span>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )
                            if (item === "propertyType") {
                                return (
                                    <List.Item>
                                        <Row style={{ width: "100%" }}>
                                            {/* <Typography.Text mark></Typography.Text> */}
                                            <Col span={12}>
                                                <span style={{ color: "silver", fontSize: 16 }}> {translateAttributes(item)}
                                                </span>
                                            </Col>
                                            <Col span={12}>
                                                <span style={{ color: "black" }}>{getPropertyType(listing[item])}</span>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )
                            }
                            return null
                        }
                        }
                    />
                    <br />
                    <pre style={{ overflow: 'auto', whiteSpace: 'pre-wrap' }} className="inter-font">{listing?.description}</pre>
                    {/* <Text style={{ width: "100%" }}>{listing?.description}</Text>   <br /> */}

                    {/* <Divider /> */}
                    {/* 
                    <List
                        header={<div>Header</div>}
                        footer={<div>Footer</div>}
                        bordered
                        dataSource={Object.values(listing)}
                        renderItem={(item) => {
                            console.log("the item is", item);
                            if (typeof item !== "object")
                                return (
                                    <List.Item>
                                        <Typography.Text mark>[ITEM]</Typography.Text> {item}
                                    </List.Item>
                                )
                        }
                        }
                    /> */}
                    {/* <Text strong style={{ width: "100%" }}>Price: ${listing?.price}</Text>   <br />

                    <Text strong>Location: {listing?.location?.address}, {listing?.location?.city}</Text>   <br />

                    <Text strong>Property Type: {listing?.propertyType}</Text>   <br />

                    <Text strong>Rooms: {listing?.rooms}</Text>   <br />

                    <Text strong>Bathrooms: {listing?.bathrooms}</Text>   <br />

                    <Text strong>Floor Area: {listing?.floorArea} m²</Text>   <br />

                    <Text strong>Whole Area: {listing?.wholeArea} m²</Text>   <br />

                    <Text strong>Listed Date: {new Date(listing?.listedDate).toLocaleDateString()}</Text>   <br /> */}

                    {/* <Divider />
                <Text strong>Contact Information:</Text>   <br />

                <Text>Name: {listing?.contactInfo?.name}</Text>   <br />

                <Text>Phone: {listing?.contactInfo?.phone}</Text>   <br />

                <Text>Email: {listing?.contactInfo?.email}</Text>   <br /> */}

                </Row>
            </Col>

            {/* <Carousel afterChange={onChange} autoplay autoplaySpeed={1000 * 1.5} >
        {
                    listing?.photos.map((image: any) => {
                        return <div>
                            <h3 style={contentStyle}>
                                <Image src={image}
                                    style={{
                                        height: '160px',
                                    }}
                                />
                            </h3>
                        </div>
                    })
                }
    </Carousel> */}
            <Col xs={0} md={12}>
                <div style={{
                    width: "100%"
                }}>


                    <Carousel autoplay arrows autoplaySpeed={1000 * 1.5}
                        dots
                    >
                        {
                            listing?.photos.map((image: any) => {
                                return <h3 style={contentStyle}>
                                    <Image src={listing.hasPreviews ? returnPreviewUrl(image) : image}
                                        style={{
                                            height: '100%',
                                            borderRadius: "10px"
                                        }}
                                    />
                                </h3>
                            })
                        }
                    </Carousel>
                    <Row gutter={[4, 2]}>
                        {
                            // @ts-ignore
                            listing?.photos?.map((photo, index) => (
                                <Col key={index} span={6}>
                                    <img
                                        src={listing.hasPreviews ? returnPreviewUrl(photo) : photo}
                                        alt={`Property ${index}`}
                                        style={{ width: '100%', cursor: 'pointer', borderRadius: "5px" }}
                                        onClick={() => handlePreview(photo)}
                                    />
                                </Col>
                            ))}
                    </Row>
                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="Preview" style={{ width: '100%', borderRadius: '0.5rem' }} src={previewImage} />
                    </Modal>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={24} md={12}>
                <div style={{ border: "5px solid gold", borderRadius: 10, width: "95%", fontSize: 18, margin: "1rem", padding: "1rem", marginRight: 0, paddingRight: 0 }}>
                    <span style={{ fontWeight: "bold" }}>Váš realitný agent:</span>
                    <br />
                    <span> Zoltán Dobos</span> <br />
                    <span> +421 905 738 632</span> <br />
                    <span>  reality.zolka9@gmail.com</span> <br />
                </div>
            </Col>
        </Row >
        <Row >
            <Col xs={24} lg={12}>
                <div style={{ padding: "2rem", paddingTop: 0 }}>
                    <Typography.Title>Mám záujem</Typography.Title>

                    <ContactForm listing={listing} />
                </div>
            </Col>
        </Row>

    </React.Fragment >


    // lazy image loading - big resolution only when needed
}


import React, { FC } from 'react';
import { Form, Input, Button } from 'antd';

type LoginProps = {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
};


export const Login: FC<LoginProps> = ({ setIsLoggedIn }) => {
  // const handleLogin = () => {
  //   // Replace this with JWT token authentication later
  //   console.log('Login credentials', { email, password });
  //   if (email === 'admin' && password === 'admin')
  //     setIsLoggedIn(true);
  //   // Here you might set the state of your app to show the logged-in user
  //   // For example: setState({ loggedIn: true, user: email });
  // };

  const onFinish = ({ email, password }: any) => {
    if (email === 'admin' && password === 'Slayer79')
      setIsLoggedIn(true);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      style={{ marginTop: "2rem" }}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;

import './App.css';

import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import { Footer, Header } from 'antd/es/layout/layout';

import { AdminPage } from './pages/Admin';
import { ListingPage } from './pages/ListingPage';
import { MainPage } from './pages/Main';
import logo from './logo-transparent.png';

function App() {
  const other = useParams<{ category: string, city: string }>();
  console.log("the category is", other);
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          // colorPrimary: 'white',
          borderRadius: 2,
          // colorText: 'black',
          colorTextDescription: 'silver',
          // Alias Token
          // colorBgContainer: 'black',
        },
      }}
    >
      <Layout>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img src={logo} style={{ width: '9rem', paddingTop: '1.5rem' }} alt="logo" />
          </a>
          {/* <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        />
         </Sider> */}
        </Header>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/properties/*" element={<ListingPage />} />
            {/* <Route path="/:id" element={<ListingPage />} /> */}
            <Route path="/admin" element={<AdminPage />} />
          </Routes>
        </BrowserRouter>
        <Footer style={{ textAlign: 'center', backgroundColor: '#001529', width: "100%", color: 'white' }}>
          <div>
            <h2 style={{ fontSize: "2rem" }}>Kontaktujte nás</h2>
            <p>Reality A&T Trade, s.r.o.</p>
            <p>reality.zolka9@gmail.com</p>
            <p>+421 905 738 632</p>
            <p>Hviezdoslavova 2, 045 01 Moldava nad Bodvou</p>
          </div>
          {/* <div style={{ marginTop: 20 }}>
        <h3>Follow Us</h3>
        <a href="https://www.facebook.com/realestatecompany">Facebook</a>&nbsp;|&nbsp;
        <a href="https://twitter.com/realestatecompany">Twitter</a>&nbsp;|&nbsp;
        <a href="https://www.instagram.com/realestatecompany">Instagram</a>
      </div> */}
          <div style={{ marginTop: 20 }}>
            <p>&copy; {new Date().getFullYear()}</p>
          </div>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
import "./styles.css";

import { Card, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";

import { FilterBar } from "../components/FilterBar";
import LandingPageSection from "../components/LandingPageSection";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatNumberWithSpaces } from "../utils/formatters";

export const MainPage = () => {
    const [listings, setListings] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(true);

    useEffect(() => {
        if (shouldUpdate) {
            axios.get('https://nodejs-production-8b99.up.railway.app/listings').then((res) => {
                setListings(res.data);
            }
            )
            setShouldUpdate(false);
        }
    }
        , [shouldUpdate])

    const [filteredData, setFilteredData] = useState(listings);

    useEffect(() => {
        setFilteredData(listings);
    }, [listings])


    const handleFilter = (filters: any) => {
        let filtered = listings;

        if (filters.title) {
            filtered = filtered.filter((item) =>
                // @ts-ignore
                item.title.toLowerCase().includes(filters.title.toLowerCase())
            );
        }

        if (filters.minPrice) {
            // @ts-ignore
            filtered = filtered.filter((item) => item.price >= filters.minPrice);
        }

        if (filters.maxPrice) {
            // @ts-ignore
            filtered = filtered.filter((item) => item.price <= filters.maxPrice);
        }

        if (filters.propertyType) {
            filtered = filtered.filter(
                // @ts-ignore
                (item) => item.propertyType === filters.propertyType
            );
        }

        setFilteredData(filtered);
    };


    return (
        <div>
            {/* <AddListing /> */}
            <LandingPageSection />
            <Typography.Title level={2} style={{ textAlign: 'center', fontSize: "3rem" }} >Naša ponuka</Typography.Title>
            <FilterBar onFilter={handleFilter} />
            <Row gutter={[16, 16]} style={{ padding: "1rem" }} id="list">
                {
                    // description, location, propertyType, rooms, bathrooms,availability, listedDate, owner,
                    // @ts-ignore
                    [...filteredData.sort((a, b) => new Date(b.listedDate) - new Date(a.listedDate))].map(({ title, price, floorArea, _id, photos, location }: any) => (
                        <Col key={_id} xs={24} sm={12} lg={8}>
                            <Link to={"/properties/" + _id}>
                                <Card
                                    hoverable
                                    title={title}
                                    cover={<img alt="example" src={photos[0]} style={{ width: "100%" }} />}
                                    onClick={() => window.location.href = "/properties/" + _id}
                                    // @ts-ignore
                                    headStyle={{ whiteSpace: 'pre-wrap', overflow: 'visible' }}
                                >
                                    {/* <div style={{ display: 'flex' }}>
                                <img src={photos?.[0]} alt={`${1}`} style={{ width: '15rem', height: 'auto' }} />
                            </div> */}
                                    {/* <p>{description}</p> */}
                                    {/* <p>Lokalita: {location?.city}, {location?.zipCode}</p> */}
                                    {/* <p>Adresa: {location?.address}</p> */}
                                    {/* <p>Typ nehnuteľnosti: {propertyType}</p> */}
                                    {/* <p>Izby: {rooms}</p> */}
                                    {/* <p>Kúpeľne: {bathrooms}</p> */}
                                    <span style={{ fontWeight: 'bold' }}>{location?.city}, Maďarsko</span>
                                    <p>{floorArea} m²</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: "2rem", color: "gold" }}>{formatNumberWithSpaces(price)} €</span>
                                        {/* <a href={"/properties/" + _id}>
                                        <Button type="primary">Detail</Button>
                                    </a> */}
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
            </Row>
        </div>
    )
}

import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { formatNumberWithSpaces } from '../utils/formatters';



const PropertyCard = ({ property, deleteListing, editListing }: any) => {
  const { location, title, description, price, propertyType, rooms, bathrooms, area, photos, availability, listedDate, _id, owner } = property;

  const firstPhoto = photos && Array.isArray(photos) && photos[0] ? photos[0] : "";

  return (
    <Card
      title={title}
      extra={<span>{formatNumberWithSpaces(price)} eur</span>}
    >
      <Row gutter={8}>
        {
          // @ts-ignore
          [firstPhoto].map((photo, index) => {
            if (index === 0) {
              return <Col key={index} span={6}>
                <img src={photo} alt={`${index + 1}`} style={{ width: '15rem', height: 'auto', border: '5px solid gold', borderRadius: 5 }} />
              </Col>
            }
            return <Col key={index} span={6}>
              <img src={photo} alt={`${index + 1}`} style={{ width: '15rem', height: 'auto', borderRadius: 5 }} />
            </Col>
          })}
      </Row>
      <pre style={{overflow: 'auto', whiteSpace: 'pre-wrap' }}>{description}</pre>
      <p>Lokalita: {location?.city}</p>
      <p>Adresa: {location?.address}</p>
      <p>Typ nehnuteľnosti: {propertyType}</p>
      <p>Izby: {rooms}</p>
      <p>Kúpeľne: {bathrooms}</p>
      <p>Plocha: {area} m²</p>
      <p>Dostupnosť: {availability}</p>
      <p>Dátum uvedenia na trh: {listedDate}</p>
      <p>Majiteľ: {owner?.name}, {owner?.phone}, {owner?.address}</p>
      <Button type="primary" style={{ marginTop: '1rem', backgroundColor: "red" }} onClick={() => deleteListing(_id)}>Vymazať</Button>
      <Button type="primary" style={{ marginTop: '1rem', marginLeft: '1rem', backgroundColor: "green" }} onClick={() => editListing(_id)}>Upraviť</Button>
    </Card>
  );
};

export default PropertyCard;

import { Layout, Typography } from 'antd';
import backgroundImage from '../house-5.png'; // Import your background image
import logo1 from '../NARKS.png';
import logo2 from '../LRM.png';

const { Content } = Layout;
const { Title } = Typography;

const LandingPageSection = () => {
    return (
        <Layout style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '70vh' }}>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginTop: '1rem' }}>
                <img src={logo1} alt="Logo 1" style={{ width: '100px', marginRight: '20px' }} />
                <img src={logo2} alt="Logo 2" style={{ width: '100px', marginRight: '20px' }} />
            </div>
            <Content style={{ padding: '100px 50px', textAlign: 'center', color: '#fff' }}>
                <Title level={2} style={{ marginBottom: '50px', fontSize: "3rem", color: "rgba(0,0,0,0.8)", textShadow: '4px 4px 7px #fff' }}>Váš vysnívaný domov v Maďarsku!</Title>
                {/* <p style={{ marginTop: '50px', fontSize: '18px' }}>
          This is a sample landing page section with a background image and logos. You can customize it according to your needs.
        </p> */}
            </Content>
        </Layout>
    );
}

export default LandingPageSection;

import React from 'react';
import PropertyCard from './PropertyCard'; // Importovanie PropertyCard komponentu
import { Col, Row } from 'antd';

const PropertyList = ({ properties, deleteListing, editListing }: any) => {
    return (
        <Row gutter={[0, 10]} style={{ marginBottom: "1rem" }}>
            {
                // @ts-ignore
                properties.map(property => (
                    <Col key={property._id} span={24}>
                        <PropertyCard key={property._id} property={property} deleteListing={deleteListing} editListing={editListing} />
                    </Col>
                ))}
        </Row>
    );
};

export default PropertyList;

import {
    Alert,
    Button,
    Form,
    Input,
} from 'antd';

import axios from 'axios';
import { useState } from 'react';

const formItemLayout = {
    // @ts-ignore
    // layout: FormLay,
    layout: 'vertical' as FormLayout,
    width: "100%",
    labelCol: {
        xs: { span: 24 },
        // sm: { span: 6 },

    },
    wrapperCol: {
        xs: { span: 24 },
        // sm: { span: 14 },
    },
};

/*
Meno a priezvisko
E-mail
Telefonne cislo
Otazka, poznamka

After sending: loading and success message, and change to form to a success message
*/

export const ContactForm: any = (props: any) => {
    const { listing } = props;

    const [isSent, setSent] = useState(false);

    const handleFinish = async (data: any) => {
        const { _id } = listing;

        const mergedData = { ...data, ...listing, listingId: _id }

        // https://nodejs-production-8b99.up.railway.app
        await axios.post('https://nodejs-production-8b99.up.railway.app/contact-form', mergedData)
            .then(async (res) => {
                console.log("The response is ", res);
                // Assuming res.data contains the URL of the uploaded image
            })
            .catch((err) => {
                console.log("The error is ", err);
            });
        setSent(true);
    }

    if (isSent) {
        return <Alert message="Ďakujeme, v blízkej dobe Vás bude kontaktovať náš realitný agent: Zoltán Dobos (+421 905 738 632)" type="success" />
    }

    if (!isSent)
        return (
            <Form {...formItemLayout}
                style={{ width: "100%" }}
                onFinish={handleFinish}
            >
                <Form.Item label="Meno a priezvisko" name="name" rules={[{ required: true, message: 'Vyplňte!' }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Telefónne číslo" name="phoneNumber" rules={[{ required: true, message: 'Vyplňte!' }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="E-mail" name="email"
                // rules={[{ required: true, message: 'Vyplňte!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Otázka/poznámka (nepovinné)"
                    name="note"
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                // wrapperCol={{ offset: 6, span: 16 }}
                >
                    <Button type="primary" htmlType="submit" style={{ borderRadius: 5 }}>
                        Odoslať
                    </Button>
                </Form.Item>
            </Form>
        );
}

import heic2any from "heic2any";

// @ts-ignore
export const convertHEICToJPEG = async (heicFile) => {
    const jpegBlob = await heic2any({
        blob: heicFile,
        toType: 'image/jpeg',
    });
    return jpegBlob;
};

import { Button, Form, Image, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Option } from 'rc-select';
import { convertHEICToJPEG } from '../utils/heic2jpeg';

// First iterate and create objects with the attribute to distinguish between the already uploaded and the new ones

export const EditListingModal = ({ listingData, visible, onClose, setShouldUpdate }: any) => {
    const [form] = Form.useForm();

    const [previewUpdateUrls, setPreviewUpdateUrls] = useState<string[] | undefined>(listingData?.photos?.map((photoUrl: string) => ({
        src: photoUrl,
        type: 'uploaded'
    })));

    console.log("the previewUpdateUrls", previewUpdateUrls);
    console.log("listingData?.photos  ", listingData?.photos);

    useEffect(() => {
        if (Array.isArray(listingData?.photos) && listingData?.photos.length > 0) {
            setPreviewUpdateUrls(listingData?.photos?.map((photoUrl: string) => ({
                src: photoUrl,
                type: 'uploaded'
            })));
        } else {
            setPreviewUpdateUrls([]);
        }
    }
        , [listingData?.photos]);


    const [fileList, setFileList] = useState([]);
    const setUploading = useState(false)[1];

    const onFinish = async (values: any) => {
        try {
            setUploading(true);
            const images: string[] = [...previewUpdateUrls?.map((url: any) => url.src)
                ?? []];
            console.log("The images are ", images);
            for (let i = 0; i < fileList.length; i++) {
                const formData = new FormData();
                const file = fileList[i];
                console.log("the file is uploading: ", file);
                // @ts-ignore
                formData.append('images', file as any, file?.name);
                console.log("The form data is ", formData);

                // Upload the current image
                await axios.post('https://nodejs-production-8b99.up.railway.app/upload/listing/image', formData)
                    .then(async (res) => {
                        console.log("The response is ", res);
                        // Assuming res.data contains the URL of the uploaded image
                        const imageUrl = res.data;
                        images.push(imageUrl[0]);
                        // Update state or do further processing as needed
                        // ...
                    })
                    .catch((err) => {
                        console.log("The error is ", err);
                        message.error('Nahratie fotky zlyhalo.');
                    });
            }
            console.log("the images are", images);
            const filteredImages = images.filter((image) => image.includes('googleapis.com'));
            if (filteredImages[filteredImages.length - 1] === filteredImages[filteredImages.length - 2]) {
                filteredImages.pop();
            }
            console.log("the images are", filteredImages);
            // After all images are uploaded
            axios.put('https://nodejs-production-8b99.up.railway.app/listings/' + listingData?._id, {
                ...values,
                // Assuming you're sending an array of image URLs as 'photos'
                photos: [...filteredImages] // Make sure res.data is accessible here or store it before the loop
            }).then(() => {
                message.success('Úspech!');
                setPreviewUpdateUrls([]);
                form.resetFields();
                setShouldUpdate(true);
            }).catch(() => {
                message.error('Nastal problém!');
            }).finally(() => {
                setUploading(false);
                // @ts-ignore
                if (document.querySelector('input[type="file"]'))
                    // @ts-ignore
                    document.querySelector('input[type="file"]')?.setAttribute('value', '');
            });
        } catch (error) {
            message.error('Nahratie inzeratu zlyhalo.');
            console.log("the error is ", error);
            setUploading(false);
        }
    };

    // Handle file change logic similar to AddListingForm

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    // @ts-ignore
    const handleFileChange = async (event) => {
        const newFileList = event.target.files;

        const convertedFileList = [];

        const photosArray = Array.from(newFileList);
        console.log("Change is called s ", photosArray);

        for (let i = 0; i < newFileList.length; i++) {
            // @ts-ignore
            if (photosArray[i].type === 'image/heic') {
                const jpegFile = await convertHEICToJPEG(photosArray[i]);
                console.log("The jpeg file is ", jpegFile);
                convertedFileList.push(jpegFile);
            } else {
                convertedFileList.push(photosArray[i]);
            }
        }

        // @ts-ignore
        setFileList(convertedFileList);

        // @ts-ignore

        const urls = convertedFileList.map(file => URL.createObjectURL(file)).map((link) => ({ src: link, type: 'new' }));
        // @ts-ignore
        setPreviewUpdateUrls(prevUrls => [...prevUrls, ...urls]);
        console.log("The preview urls are ", previewUpdateUrls);
        // Handle file selection logic here
    };
    // @ts-ignore


    const handlePreview = (url) => {
        setPreviewImage(url);
        setPreviewVisible(true);
    };
    const handleModalCancel = () => {
        setPreviewVisible(false);
    };
    // @ts-ignore

    const handleDelete = (index) => {
        setPreviewUpdateUrls(prevUrls => prevUrls?.filter((_, i) => i !== index));
        console.log("The file list is ", fileList);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);

        // Implement logic to delete the image
    };


    return (
        <Modal
            visible={visible}
            title="Edit Listing"
            onCancel={onClose}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={listingData} // Set initial values for the form fields
            >
                <Form.Item
                    label="Názov"
                    name="title"
                    rules={[{ required: true, message: 'Prosím, doplňte názov!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Popis" name="description">
                    <Input.TextArea style={{ height: '20rem' }} />
                </Form.Item>

                <Form.Item
                    label="Cena"
                    name="price"
                    required
                // rules={[
                //     { required: true, min: 0, message: 'Prosím, doplňte cenu!' }
                // ]}
                >
                    <Input type='number' style={{ width: '100%' }} suffix="€" />
                </Form.Item>


                <Form.Item label="Poloha"
                    required

                >
                    <Input.Group compact>
                        <Form.Item name={['location', 'city']} noStyle>
                            <Input style={{ width: '100%' }} placeholder="Obec" />
                        </Form.Item>
                        {/* <Form.Item name={['location', 'zipCode']} noStyle>
                            <Input style={{ width: '100%' }} placeholder="PSČ" />
                        </Form.Item> */}
                        <Form.Item name={['location', 'address']} noStyle>
                            <Input style={{ width: '100%' }} placeholder="Adresa" />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    label="Typ nehnuteľnosti"
                    name="propertyType"
                    required
                    rules={[{ required: true, message: 'Prosím, vyberte typ nehnuteľnosti!' }]}
                >
                    <Select>
                        <Option value="family_house">Rodinný dom</Option>
                        <Option value="cottage">Chata</Option>
                        <Option value="country_house">Vidiecky dom</Option>
                        <Option value="farm_estate">Farmárska usadlosť</Option>
                        <Option value="mobile_house">Mobilný dom</Option>
                        <Option value="houseboat">Hausbót</Option>
                        <Option value="log_house">Zrub</Option>
                        <Option value="garden_house">Záhradný domček</Option>
                        <Option value="building">Budova</Option>
                        <Option value="villa">Vila</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Podlahová plocha"
                    name="floorArea"
                    // rules={[{ required: true, min: 0, message: 'Prosím, zadajte podlahovú plochu!' }]}
                    required
                >
                    <Input type='number' style={{ width: '100%' }} suffix="m²" />
                </Form.Item>

                <Form.Item
                    label="Celková plocha"
                    name="wholeArea"
                    // rules={[{ required: true, min: 0, message: 'Prosím, zadajte celkovú plochu!' }]}
                    required
                >
                    <Input type='number' style={{ width: '100%' }} suffix="m²" />
                </Form.Item>

                <Form.Item
                    label="Počet izieb"
                    name="rooms"
                    // rules={[{ required: true, min: 0, message: 'Prosím, zadajte počet izieb!' }]}
                    required
                >
                    <Input type='number' style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    label="Počet kúpeľní"
                    name="bathrooms"
                    // rules={[{ required: true, min: 0, message: 'Prosím, zadajte počet kúpeľní!' }]}
                    required
                >
                    <Input type='number' style={{ width: '100%' }}
                    />
                </Form.Item>
                <div>
                    <div>
                        <Input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            multiple // Allow multiple file selection
                        />
                        <Button icon={<UploadOutlined />} onClick={() =>
                            // @ts-ignore

                            document.querySelector('input[type="file"]').click()}>
                            Vybrať súbory
                        </Button>
                        <div style={{ marginTop: '20px' }}>
                            {previewUpdateUrls?.map((url: any) => (url?.src)).map((url, index) => (
                                <div key={index} style={{ display: 'inline-block', marginRight: '10px', position: 'relative' }}>
                                    <Image
                                        src={url}
                                        width={index === 0 ? 250 + 200 : 150 + 100} // Adjust the width for the first preview image
                                        // height={index ==s= 0 ? 250 : 150} // Adjust the height for the first preview image
                                        style={{
                                            marginBottom: '10px', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                            border: index === 0 ? '5px solid black' : undefined
                                        }}
                                    />
                                    <Button
                                        type="primary"
                                        size="small"
                                        icon={<EyeOutlined />}
                                        style={{ position: 'absolute', top: 0, right: '60px' }}
                                        onClick={() => handlePreview(url)}
                                    />
                                    <Button
                                        danger
                                        size="small"
                                        icon={<DeleteOutlined />}
                                        style={{ position: 'absolute', top: 0, right: 0 }}
                                        onClick={() => handleDelete(index)}
                                    />
                                </div>
                            ))}
                        </div>
                        <Modal
                            visible={previewVisible}
                            title="Preview Image"
                            footer={null}
                            onCancel={handleModalCancel}
                        >
                            <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                </div>

                <Form.Item label="Majiteľ"
                    required
                >
                    <Input.Group compact>
                        <Form.Item name={['owner', 'name']} noStyle>
                            <Input style={{ width: '100%' }} placeholder="Meno" />
                        </Form.Item>
                        <Form.Item name={['owner', 'phone']} noStyle>
                            <Input style={{ width: '100%' }} placeholder="Telefónne číslo" />
                        </Form.Item>
                        <Form.Item name={['owner', 'address']} noStyle>
                            <Input style={{ width: '100%' }} placeholder="Adresa" />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>


                <Form.Item label="Stav" name="availability" required
                    rules={[{ required: true, message: 'Prosím, vyberte stav ponuky!' }]}
                >
                    <Select>
                        <Option value="available">Dostupné</Option>
                        <Option value="reserved">Rezervované</Option>
                        <Option value="sold">Predané</Option>
                        <Option value="not_available">Nedostupné</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Odoslať
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

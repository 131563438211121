import { Button, Col, Input, Row, Select } from 'antd';
// src/components/FilterBar.js
import React, { useState } from 'react';

const { Option } = Select;

export const FilterBar = ({ onFilter }: any) => {
    const [title, setTitle] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [propertyType, setPropertyType] = useState('');

    const handleFilter = () => {
        onFilter({ title, minPrice, maxPrice, propertyType });
    };

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: 20, marginLeft: 20, marginRight: 20 }}>
            <Col xs={24} sm={12} md={6}>
                <Input
                    placeholder="Podľa názvu"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Col>
            <Col xs={24} sm={12} md={6}>
                <Input
                    placeholder="Minimálna cena"
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                />
            </Col>
            <Col xs={24} sm={12} md={6}>
                <Input
                    placeholder="Maximálna cena"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                />
            </Col>
            <Col xs={24} sm={12} md={6}>
                <Select
                    placeholder="Typ nehnuteľnosti"
                    value={propertyType}
                    onChange={(value) => setPropertyType(value)}
                    style={{ width: '100%' }}
                    allowClear
                >
                    <Option value="family_house">Rodinný dom</Option>
                    <Option value="cottage">Chata</Option>
                    <Option value="country_house">Vidiecky dom</Option>
                    <Option value="building">Budova</Option>
                    <Option value="hotel">Penzión</Option>
                </Select>
            </Col>
            <Col xs={24} sm={24} md={24} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={handleFilter} style={{ borderRadius: 10 / 2 }}>
                    Hľadaj
                </Button>
            </Col>
        </Row>
    );
};
